import React, { useEffect } from "react"
import { Button, DialogContent, DialogTitle, ForwardIcon, Stepper } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { DialogActionsBar, Form, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { CarrotMatchDialogCloseButton } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CarrotMatchDialogCloseButton"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { User } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"

export function WillYouBeReceivingTheCareStep(): JSX.Element {
  const { send } = useStateMachine()
  const { updateCarrotMatchInteraction } = useCarrotMatch()
  const intl = useIntl()

  useEffect(() => {
    updateCarrotMatchInteraction({ lastQuestionViewedBeforeLeavingFlow: "Will you be receiving the care?" })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const onSubmit: FormOnSubmitHandler<{ willMemberBeReceivingTheCare: string }> = ({
    willMemberBeReceivingTheCare
  }) => {
    switch (willMemberBeReceivingTheCare) {
      case "YES":
        updateCarrotMatchInteraction({ whoIsSeekingCare: User.MEMBER, whoIsAnsweringTheIntake: User.MEMBER })
        break
      case "NO":
        updateCarrotMatchInteraction({ whoIsSeekingCare: User.PARTNER, whoIsAnsweringTheIntake: User.MEMBER })
        break
    }
    send(willMemberBeReceivingTheCare)
  }
  return (
    <>
      <Form onSubmit={onSubmit}>
        <CarrotMatchDialogCloseButton />
        <Stepper variant="progress" steps={4} activeStep={0} position="static" />
        <DialogTitle id="step-heading">
          <FormattedMessage defaultMessage="Will you be receiving the care?" />
        </DialogTitle>
        <DialogContent>
          <FormRadioButtonGroup
            aria-labelledby="step-heading"
            name="willMemberBeReceivingTheCare"
            controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
            options={[
              { label: intl.formatMessage({ defaultMessage: "Yes" }), value: "YES" },
              { label: intl.formatMessage({ defaultMessage: "No" }), value: "NO" }
            ]}
          />
        </DialogContent>
        <DialogActionsBar
          primaryAction={
            <Button variant="outlined" color="secondary" endIcon={<ForwardIcon />} type="submit">
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          }
        />
      </Form>
    </>
  )
}
