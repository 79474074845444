import React, { useContext } from "react"
import { IntlConfig, IntlProvider, useIntl } from "react-intl"
import { Helmet } from "react-helmet"
import { AdapterDayjs, LocalizationProvider, dayjs } from "@carrotfertility/carotene-core"
import { setHeapLocale } from "#/utils/heap"
import { getLastUsedLocale, setLastUsedLocale } from "../../../utils/lastUsedLocale"
import { setOneTrustLocale } from "#/utils/oneTrustLocale"
import { DEFAULT_LOCALE, Locale, SupportedLocale } from "../../../types/Locale"
import { reportError } from "#/utils/ErrorReporting"

type CompiledMessages = IntlConfig["messages"]

type LocaleProps = {
  setLocale: (locale: SupportedLocale) => void
  didCompiledMessagesLoad: boolean
}

const LocaleContext = React.createContext<LocaleProps>({
  setLocale: null,
  didCompiledMessagesLoad: false
})

async function loadLocaleMessages(locale: Locale): Promise<CompiledMessages> {
  return import(`compiledLang/${locale.toString()}.json`)
}

async function loadPolyfills(locale: Locale): Promise<void> {
  if (locale instanceof SupportedLocale) {
    try {
      await import(`intlPolyfills/${locale.toString()}/index.ts`)
    } catch (error) {
      reportError(
        new Error(`Could not load polyfills for locale\nLocale: [${locale?.toString()}] \nCause:${error.message}`, {
          cause: error.cause
        })
      )
      /* Ignored so that an error doesn't prevent app from loading */
    }
  }
}

function loadFont(locale: Locale): void {
  const fontUrls: { [key: string]: string } = {
    [SupportedLocale.Thai_Thailand.toString()]:
      "https://fonts.googleapis.com/css2?family=Noto+Sans+Thai+Looped:wght@100;200;300;400;500;600;700;800;900&display=swap",
    [SupportedLocale.SimplifiedChinese_China.toString()]:
      "https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100..900&display=swap",
    [SupportedLocale.TraditionalChinese_Taiwan.toString()]:
      "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap",
    [SupportedLocale.Japanese_Japan.toString()]:
      "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap",
    [SupportedLocale.Arabic_MSA.toString()]:
      "https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap",
    [SupportedLocale.Urdu_Pakistan.toString()]:
      "https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap",
    [SupportedLocale.Georgian_Georgia.toString()]:
      "https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@100..900&display=swap"
  }

  const fontUrl = fontUrls[locale.toString()]
  if (fontUrl) {
    const link = document.createElement("link")
    link.rel = "stylesheet"
    link.href = fontUrl
    document.head.appendChild(link)
  }
}

export const useLocaleContext = () => useContext(LocaleContext)

export function IntlProviderWrapper(props: React.PropsWithChildren) {
  const lastUsedLocale = getLastUsedLocale()

  const [locale, setLocale] = React.useState<SupportedLocale>(lastUsedLocale ?? DEFAULT_LOCALE)
  const [compiledMessages, setCompiledMessages] = React.useState<CompiledMessages>()
  const [didCompiledMessagesLoad, setDidCompiledMessagesLoad] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOneTrustLocale(locale)
    setLastUsedLocale(locale)
    setHeapLocale(locale)
    async function getCompiledMessages(locale: SupportedLocale): Promise<void> {
      try {
        const [messages] = await Promise.all([loadLocaleMessages(locale), loadPolyfills(locale)])

        loadFont(locale)
        setCompiledMessages(messages)
        setDidCompiledMessagesLoad(true)
      } catch (err) {
        reportError(
          new Error(`Could not load locale messages\nLocale: [${locale?.toString()}] \nCause:${err.message}`, {
            cause: err.cause
          })
        )
      }
    }
    getCompiledMessages(locale)
  }, [locale])

  return (
    <LocaleContext.Provider value={{ setLocale, didCompiledMessagesLoad }}>
      <IntlProvider locale={locale?.toString()} defaultLocale={DEFAULT_LOCALE.toString()} messages={compiledMessages}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale(locale?.toString())}>
          <DocumentLocalizationAttributes />
          {props.children}
        </LocalizationProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  )
}

function DocumentLocalizationAttributes() {
  const { locale } = useIntl()
  const { textDirection } = Locale.getLocaleFromString(locale)

  return (
    <Helmet>
      <html lang={locale} dir={textDirection} />
    </Helmet>
  )
}
