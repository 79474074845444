import React from "react"
import { SignUpFlowMemberInfo, useSignUpFlow } from "../../context/signup/SignUpFlowContext"
import { getHeap } from "#/utils/heap"
import { redirectToSamlExternalLogin } from "#/utils/RedirectToSamlExternalLogin"
import { useIntl } from "react-intl"
import { carrotClient } from "#/utils/CarrotClient"
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { MemberInfo, useNoEmailOnboardingForm } from "#/components/signup-shared/NoEmailOnboardingFormContext"
import { FindAccountByEmployeeIdPage } from "#/components/sign-up-find-account-company-specific"

export type FindAccountByExternalEmployeeIdPayload = {
  externalEmployeeId: string
  parentCompanyId: number
  dateOfBirth: string
  // eslint-disable-next-line no-restricted-syntax -- This is the user's preferredLocale based on their selection during registration. Carrot-app's LocaleService will then determine if they can use the selected language once they sign up.
  preferredLocale: string
}

const useFindAccountByExternalEmployeeIdMutation = (): UseMutationResult<
  MemberInfo,
  Error,
  FindAccountByExternalEmployeeIdPayload
> => {
  return useMutation(
    (payload: FindAccountByExternalEmployeeIdPayload): Promise<MemberInfo> =>
      carrotClient.signUpExternalEmployeeId(payload)
  )
}

export function FindAccountByExternalEmployeeIdContainer({
  parentCompanyId
}: {
  parentCompanyId: number
}): JSX.Element {
  const { handleSaveMemberInfoInMemory } = useSignUpFlow()
  const { locale } = useIntl()
  const findAccountMutation = useFindAccountByExternalEmployeeIdMutation()
  const { onEmailFound, onEmailNotFound, onEmployeeAlreadyRegistered, onEmployeeHasSamlAccount, onError } =
    useNoEmailOnboardingForm()

  const onSubmit = async ({ externalEmployeeId, parentCompanyId, dateOfBirth }: SignUpFlowMemberInfo) => {
    getHeap().track("NoEmailOnboarding", { EventName: "submit external employee ID for signup" })
    findAccountMutation.mutate(
      // eslint-disable-next-line no-restricted-syntax -- This is the user's preferredLocale based on their selection during registration. Carrot-app's LocaleService will then determine if they can use the selected language once they sign up.
      { externalEmployeeId, parentCompanyId, dateOfBirth: dateOfBirth.format("YYYY-MM-DD"), preferredLocale: locale },
      {
        onSuccess: (data: MemberInfo) =>
          onFindAccountSuccess(data, { externalEmployeeId, parentCompanyId, dateOfBirth }),
        onError: (error: any) => {
          onError(error, { externalEmployeeId, parentCompanyId, dateOfBirth })
        }
      }
    )
  }

  const onFindAccountSuccess = (
    memberInfo: MemberInfo,
    { externalEmployeeId, parentCompanyId, dateOfBirth }: SignUpFlowMemberInfo
  ) => {
    if (memberInfo.samlIdpEntityId && memberInfo.existingEmail) {
      onEmployeeHasSamlAccount(memberInfo.existingEmail)
      return
    } else if (
      memberInfo.samlIdpEntityId &&
      memberInfo.ssoOnly &&
      // is this necessary???
      memberInfo.hasChannelMemberId &&
      !memberInfo.existingEmail
    ) {
      // SSO Enabled No Email Onboarding member must authenticate before providing their email.
      redirectToSamlExternalLogin(memberInfo.samlExternalLoginUrl, memberInfo.samlIdpEntityId, false)
      return
    }

    if (memberInfo.existingEmail) {
      if (!memberInfo.employeeAlreadyRegistered) {
        onEmailFound(memberInfo.existingEmail)
      } else {
        handleSaveMemberInfoInMemory({
          externalEmployeeId,
          email: memberInfo.existingEmail,
          parentCompanyId,
          dateOfBirth
        })
        onEmployeeAlreadyRegistered()
      }
    } else {
      onEmailNotFound({ externalEmployeeId, parentCompanyId, dateOfBirth })
    }
  }

  return (
    <FindAccountByEmployeeIdPage
      parentCompanyId={parentCompanyId}
      onSubmit={onSubmit}
      isRequestPending={findAccountMutation.isLoading}
    />
  )
}
