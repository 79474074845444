import { useMutation, UseMutationResult } from "@tanstack/react-query"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { carrotClient } from "#/utils/CarrotClient"
import { useSignUpFlow } from "#/components/context/signup/SignUpFlowContext"
import { useNoEmailOnboardingForm } from "#/components/signup-shared/NoEmailOnboardingFormContext"
import { redirectToSamlExternalLogin } from "#/utils/RedirectToSamlExternalLogin"
import SignUpShareZipCodePage from "#/components/signup-share-zip-code"

export interface SignUpVerifyZipCodePayload {
  firstName: string
  lastName: string
  dateOfBirth: string
  zipCode: string
}

export interface SignUpFindMemberByZipCodePayload extends SignUpVerifyZipCodePayload {
  // eslint-disable-next-line no-restricted-syntax -- This is the user's preferredLocale based on their selection during registration. Carrot-app's LocaleService will then determine if they can use the selected language once they sign up.
  preferredLocale: string
}

function useSignUpVerifyZipCodeMutation(): UseMutationResult<void, Error, SignUpVerifyZipCodePayload> {
  return useMutation((payload: SignUpVerifyZipCodePayload): Promise<void> => carrotClient.signUpVerifyZipCode(payload))
}

function useFindMemberByZipCodeMutation(): UseMutationResult<void, Error, SignUpFindMemberByZipCodePayload> {
  return useMutation(
    (payload: SignUpFindMemberByZipCodePayload): Promise<void> => carrotClient.signUpFindMemberByZipCode(payload)
  )
}

function SignUpShareZipCodeContainer() {
  const history = useHistory()
  const verifyZipCode = useSignUpVerifyZipCodeMutation()
  const findMemberByZipCode = useFindMemberByZipCodeMutation()
  const { handleSaveMemberInfoInMemory, email, firstName, lastName, dateOfBirth, multipleEmployeesFound, locale } =
    useSignUpFlow()
  const { onEmailFound, onEmailNotFound, onEmployeeAlreadyRegistered, onEmployeeHasSamlAccount, onError } =
    useNoEmailOnboardingForm()

  useEffect(() => {
    if ([firstName, lastName, dateOfBirth].some((e) => e == null)) {
      history.push("/signup/info")
    }
  }, [firstName, lastName, dateOfBirth, history])

  const onSubmit = async (data: { zipCode: string }) => {
    if (!multipleEmployeesFound) {
      verifyZipCode.mutate(
        { firstName, lastName, dateOfBirth: dateOfBirth.format("YYYY-MM-DD"), zipCode: data.zipCode },
        {
          onSuccess: () => onEmailNotFound({ email, dateOfBirth, firstName, lastName }),
          onError: (error) => {
            onError(error, { firstName, lastName, dateOfBirth, email })
          }
        }
      )
    } else if (data.zipCode !== "") {
      findMemberByZipCode.mutate(
        {
          firstName,
          lastName,
          dateOfBirth: dateOfBirth.format("YYYY-MM-DD"),
          zipCode: data.zipCode,
          // eslint-disable-next-line no-restricted-syntax -- This is the user's preferredLocale based on their selection during registration. Carrot-app's LocaleService will then determine if they can use the selected language once they sign up.
          preferredLocale: locale
        },
        {
          onSuccess: (data) => onFindMemberSuccess(data),
          onError: (error) => {
            onError(error, { firstName, lastName, dateOfBirth, email })
          }
        }
      )
    } else {
      history.push("/signup-get-help")
    }
  }

  const onFindMemberSuccess = (memberInfo: any) => {
    if (memberInfo.samlIdpEntityId && memberInfo.existingEmail) {
      onEmployeeHasSamlAccount(memberInfo.existingEmail)
      return
    } else if (memberInfo.samlIdpEntityId && memberInfo.ssoOnly && !memberInfo.existingEmail) {
      // SSO Enabled No Email Onboarding member must authenticate before providing their email.
      redirectToSamlExternalLogin(memberInfo.samlExternalLoginUrl, memberInfo.samlIdpEntityId, false)
      return
    }

    if (memberInfo.existingEmail) {
      if (!memberInfo.employeeAlreadyRegistered) {
        onEmailFound(memberInfo.existingEmail)
      } else {
        handleSaveMemberInfoInMemory({ email: memberInfo.existingEmail })
        onEmployeeAlreadyRegistered()
      }
    } else {
      onEmailNotFound({ email, dateOfBirth, firstName, lastName })
    }
  }

  const onBack = () => {
    history.push("/signup/info")
  }

  const onSkip = () => {
    history.push("/signup-get-help")
  }

  return (
    <SignUpShareZipCodePage
      onSubmit={onSubmit}
      onBack={onBack}
      onSkip={onSkip}
      isRequestPending={findMemberByZipCode.isLoading || verifyZipCode.isLoading}
    />
  )
}

export default SignUpShareZipCodeContainer
